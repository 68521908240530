<template>
  <BaseListPage locale-section="pages.divisions" route="division">
    <DivisionsList />
  </BaseListPage>
</template>

<script>
export default {
  name: "DivisionsPage",
  components: {
    BaseListPage: () => import("@/components/BaseListPage"),
    DivisionsList: () => import("./DivisionsList")
  }
};
</script>
